/* You can add global styles to this file, and also import other style files */

/* The emerging W3C standard
   that is currently Firefox-only */

* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c2 transparent;
}


/* Works on Chrome/Edge/Safari */

*::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border-radius: 5px;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f5f7fa;
}

a {
  text-decoration: none;
}

.center {
  display: flex;
  justify-content: center;
}

h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 300;
}

h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 300;
}

h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 300;
}

.badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}

.badge-success {
  background: #3d7937;
  border-radius: 5px;
  padding: 2px 10px;
  color: #FFF;
  font-size: 0.85rem;
}

.badge-danger {
  background: #fa4d37;
  border-radius: 5px;
  padding: 2px 10px;
  color: #fff;
  font-size: 0.85rem;
}

.badge-neutral {
  background: #d5d5d5;
  border-radius: 5px;
  padding: 2px 10px;
  color: #000000;
  font-size: 0.85rem;
}

.dark-mode .badge-neutral {
  background: #07060742;
  border-radius: 5px;
  padding: 2px 10px;
  color: #fff;
  font-size: 0.85rem;
}

.badge-white {
  background: #d1ced1e8;
  border-radius: 5px;
  padding: 2px 10px;
  color: rgb(99, 43, 110);
  font-size: 0.85rem;
}

.badge-info {
  background: #0a8cfb;
  border-radius: 5px;
  padding: 2px 10px;
  color: rgb(255, 255, 255);
  font-size: 0.85rem;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 10px !important;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}


.pl {
  &-0 {
    padding-left: 0px !important;
  }

  &-10 {
    padding-left: 10px !important;
  }

  &-20 {
    padding-left: 20px !important;
  }

  &-30 {
    padding-left: 30px !important;
  }

  &-40 {
    padding-left: 40px !important;
  }

  &-50 {
    padding-left: 50px !important;
  }
}

.pr {
  &-0 {
    padding-right: 0px !important;
  }

  &-10 {
    padding-right: 10px !important;
  }

  &-20 {
    padding-right: 20px !important;
  }

  &-30 {
    padding-left: 30px !important;
  }

  &-40 {
    padding-left: 40px !important;
  }

  &-50 {
    padding-right: 50px !important;
  }
}

.pt {
  &-0 {
    padding-top: 0px !important;
  }

  &-5 {
    padding-top: 5px !important;
  }

  &-10 {
    padding-top: 10px !important;
  }

  &-20 {
    padding-top: 20px !important;
  }

  &-30 {
    padding-left: 30px !important;
  }

  &-40 {
    padding-left: 40px !important;
  }

  &-50 {
    padding-top: 50px !important;
  }
}

.pb {
  &-0 {
    padding-bottom: 0px !important;
  }

  &-5 {
    padding-bottom: 5px !important;
  }

  &-10 {
    padding-bottom: 10px !important;
  }

  &-20 {
    padding-bottom: 20px !important;
  }

  &-30 {
    padding-left: 30px !important;
  }

  &-40 {
    padding-left: 40px !important;
  }

  &-50 {
    padding-bottom: 50px !important;
  }
}

.mt {
  &-0 {
    margin-top: 0px !important;
  }

  &-10 {
    margin-top: 10px !important;
  }

  &-20 {
    margin-top: 20px !important;
  }

  &-30 {
    margin-top: 30px !important;
  }

  &-50 {
    margin-top: 50px !important;
  }
}

.mb {
  &-0 {
    margin-bottom: 0px !important;
  }

  &-10 {
    margin-bottom: 10px !important;
  }

  &-20 {
    margin-bottom: 20px !important;
  }

  &-30 {
    margin-bottom: 30px !important;
  }

  &-50 {
    margin-bottom: 50px !important;
  }
}


.ml {
  &-0 {
    margin-left: 0px !important;
  }

  &-5 {
    margin-left: 5px !important;
  }

  &-10 {
    margin-left: 10px !important;
  }

  &-20 {
    margin-left: 20px !important;
  }

  &-30 {
    margin-left: 30px !important;
  }

  &-50 {
    margin-left: 50px !important;
  }
}

.mr {
  &-0 {
    margin-right: 0px !important;
  }

  &-5 {
    margin-right: 5px !important;
  }

  &-10 {
    margin-right: 10px !important;
  }

  &-20 {
    margin-right: 20px !important;
  }

  &-30 {
    margin-right: 30px !important;
  }

  &-50 {
    margin-right: 50px !important;
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }

  &-10 {
    padding: 10px !important;
  }
}

.m {
  &-0 {
    margin: 0 !important;
  }

  &-10 {
    margin: 10px !important;
  }
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-normalbold {
  font-weight: 500 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fst-italic {
  font-style: italic !important;
}

.white {
  color: #ffffff !important;
}

.fs {
  &-0-5 {
    font-size: 0.5rem !important;
  }

  &-0-75 {
    font-size: 0.75rem !important;
  }

  &-0-85 {
    font-size: 0.85rem !important;
  }

  &-1 {
    font-size: 1rem !important;
  }

  &-1-5 {
    font-size: 1.5rem !important;
  }
}

/**************************/
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

.mat-drawer-container {
  background-color: #e9eaec;
}

.mat-dialog-container,
.mat-menu-panel {
  border-radius: 1rem !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.mat-button-base {
  border-radius: 0.5rem !important;
}

.mat-button-base.btn-mini {
  padding: 0 10px !important;
  line-height: 30px !important;
  font-size: 0.85rem !important;
  border-radius: 1rem !important;
  font-weight: 400 !important;
}

.btn-float-right {
  top: 50% !important;
  right: 10px !important;
  margin-top: -15px !important;
}

.mat-form-field {
  width: 100%;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.card-modal {
  border-radius: 10px !important;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  border: 1px solid #e0e0e0;
}

.dark-mode .card-modal {
  border-radius: 10px !important;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  border: 1px solid #7b7b7b;
}

.modal-unauthorized .mat-dialog-container {
  background-color: #ed1648;
}

.table-responsive {
  display: inline-grid;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 10px !important;
  /*box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;*/
  margin-bottom: 30px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  border-bottom: 0;
}

.dark-mode .table-responsive {
  border: none;
  background: #424242;
}

.table-responsive.table-responsive-sticky {
  padding: 5px 0px;
}

.table-sticky {
  max-height: 500px;
  overflow: auto;
}

.mat-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}


/*tablas*/

.mat-row:hover .mat-cell {
  background: rgba(224, 224, 224, 0.5);
}

.dark-mode .mat-row:hover .mat-cell {
  background: rgba(255, 255, 255, 0.12);
}

.mat-column-position {
  width: 100px;
  border-right: 1px solid rgba(224, 224, 224);
  text-align: center !important;
}

.dark-mode .mat-column-position {
  width: 100px;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  text-align: center !important;
}

.mat-column-actions {
  border-left: 1px solid rgba(224, 224, 224);
  text-align: center !important;
}

.dark-mode .mat-column-actions {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  text-align: center !important;
}

.mat-header-cell:nth-child(1),
.mat-cell:nth-child(1) {
  padding-left: 0 !important;
}

.mat-header-cell:nth-child(2),
.mat-cell:nth-child(2) {
  padding-left: 1rem !important;
}

.close-button {
  float: right;
  top: -24px;
  right: -24px;
}

.card-selected {
  background-color: #b3dfff !important;
}

.new-tooltip {
  background-color: #000000 !important;
  font-size: 1rem !important;
}

.img-container {
  background: url(./assets/loading.gif) no-repeat center;
  min-width: 64px;
  min-height: 64px;
}

.img-container>img {
  width: 100% !important;
  border-radius: 0.5rem;
}

.title-graph {
  font-size: 1rem;
  margin-bottom: 20px;
  background: #f5f5f5;
  color: #333333;
  border-radius: 5px;
  padding: 10px 0px;
}

/*DARK MODE*/

.dark-mode .title-graph {
  font-size: 1rem;
  margin-bottom: 20px;
  background: #333333;
  color: #ececec;
  border-radius: 5px;
  padding: 10px 0px;
}

.dark-mode .sidebar .section {
  color: #efefef !important;
}

.dark-mode .sidebar a {
  color: #8391a2 !important;
}

.dark-mode .sidebar a:hover,
.dark-mode .sidebar a.selected {
  color: #bccee4 !important;
}

.dark-mode .table-modal {
  background-color: #2d2d2d !important;
}

.dark-mode .card-selected {
  background-color: #3f8bc3 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #a6a6a6;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: #c6c6c6;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
  font-weight: 600;
}

.view-multiple>div {
  width: 33.33%;
  float: left;
}

.view-grid>div {
  width: 50%;
  float: left;
}

.view-list>div {
  width: 100%;
}

.mat-radio-button~.mat-radio-button {
  margin-left: 16px;
}

.mat-dialog-content {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.swal2-title {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.swal2-popup {
  width: 20rem !important;
  border-radius: 1rem !important;
}

.dark-mode .swal2-popup {
  background-color: #212121 !important;
}

.dark-mode .swal2-title {
  color: #cccccc !important;
}

.ngx-toastr {
  border-radius: 0px !important;
}

.ngx-toastr,
.ngx-toastr:hover {
  box-shadow: none !important;
}

.toast-success {
  background-color: #5bc45b !important;
}

.toast-success:hover {
  background-color: #30b630 !important;
}

.severity-0 {
  background-color: gray;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 400;
}

.severity-1 {
  background-color: lightblue;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 400;
}

.severity-2 {
  background-color: yellow;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 400;
}

.severity-3 {
  background-color: orange;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 400;
}

.severity-4 {
  background-color: lightcoral;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 400;
}

.severity-5 {
  background-color: red;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-size: 0.75rem;
  font-weight: 400;
}

.ticket-status-0 {
  color: #009900;
}

.ticket-status-1 {
  color: #ff0000;
}

.position-menu {
  background: #cccccc;
  padding: 2px 5px;
  margin-right: 10px;
  border-radius: 5px;
}

.small-container {
  max-height: 150px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 10px;
}

.item-container {
  border: 1px dashed #8b8b8b;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 0.70rem;
  position: relative;
}

.item-semaphore-left {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -10px;
}

.item-semaphore-center {
  padding-left: 30px;
  padding-right: 30px;
}

.item-semaphore-right {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
}

.item-semaphore-2 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(236, 8, 8);
  display: block;
  animation: opacityAnimation 0.5s infinite;
}

.item-semaphore-legend-2 {
  background-color: rgb(236, 8, 8);
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}

.semaphore-legend-error {
  background-color: rgb(236, 8, 8);
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
}

.item-semaphore-1 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #43d71e;
  display: block;
  animation: opacityAnimation 0.5s infinite;
}

.item-semaphore-legend-1 {
  background-color: #43d71e;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}

.semaphore-legend-ok {
  background-color: #43d71e;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
}

.item-semaphore-0 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: lightgray;
  display: block;
}

.item-semaphore-legend-0 {
  background-color: lightgray;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}

.semaphore-legend-ambar {
  background-color: #f7831d;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
}

.btn-clean-auto {
  padding: 0 !important;
  width: auto !important;
  height: auto !important;
  line-height: normal !important;
}

.new-tooltip {
  background-color: #000000 !important;
  font-size: 1rem !important;
  white-space: pre-line !important;
}

.mat-optgroup-label {
  background-color: #e9eaec;
  font-weight: 500;
}

.dark-mode .mat-optgroup-label {
  background-color: #303030;
  font-weight: 500;
}

.simple-semaphore {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: red;
  margin-bottom: 20px;
}

.container-range-date .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-snack-bar-container.snack-error {
  background-color: rgb(240, 98, 98);
  color: #fff;
}

.mat-snack-bar-container.snack-error .mat-simple-snackbar-action {
  color: #fff;
}

.alert {
  background-color: #fff3cd;
  padding: 1rem;
  color: #664d03;
  border: 1px solid #ffecb5;
  border-radius: 0.375rem;
}

.alert-info {
  background-color: #cff4fc;
  color: #055160;
  border: 1px solid #b6effb;
  border-radius: 0.375rem;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: .25;
}

@media (max-width: 600px) {
  .th-50 {
    width: 50px !important;
  }

  .th-100 {
    width: 100px !important;
  }

  .th-200 {
    width: 200px !important;
  }

  .th-300 {
    width: 300px !important;
  }

  .th-400 {
    width: 400px !important;
  }

  .th-500 {
    width: 500px !important;
  }

  .header-options{
    flex-direction:column!important;
    align-items:flex-start!important;
  }

}

/**
animate
*/


@keyframes opacityAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-o-keyframes opacityAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes opacityAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes opacityAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.afu-select-btn,
.afu-upload-btn,
.afu-reset-btn {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 20px !important;
  font-weight: 400 !important;
}

.afu-select-btn {
  background: #3949a2;
  color: #efecec;
}

.afu-reset-btn {
  background-color: cornflowerblue;
  color: #efecec;
}

.afu-upload-btn:hover {
  opacity: 0.85;
}

.afu-upload-btn {
  background: #7fffd4;
}

.mat-tooltip {
  background-color: #000000 !important;
  font-size: 0.8rem !important;
  color: #ffffff !important;
}

.dark-mode .mat-tooltip {
  background-color: #000000 !important;
  font-size: 0.8rem !important;
  color: #ffffff !important;
}

.code-style {
  background: floralwhite;
  color: black;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 0.75rem;
  font-weight: 600;
}

.circle-success {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #11db55;
  display: inline-block;
}

.circle-danger {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #f13b69;
  display: inline-block;
}

.circle-info {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #188fff;
  display: inline-block;
}

.input-plain {
  border: 1px solid #a6a6a6;
  border-radius: 5px;
  padding: 17px 10px;
  margin-top: 3px;
}

.mat-cell, .mat-footer-cell{
  padding: 5px!important;
}