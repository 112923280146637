@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #e0f1ff,
    100 : #b3ddfe,
    200 : #80c6fe,
    300 : #4daffe,
    400 : #269efd,
    500 : #008dfd,
    600 : #0085fd,
    700 : #007afc,
    800 : #0070fc,
    900 : #005dfc,
    A100 : #ffffff,
    A200 : #eff4ff,
    A400 : #bcd0ff,
    A700 : #a2bfff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
/* For use in src/lib/core/theming/_palette.scss */
$md-accent: (
    50 : #fffcf3,
    100 : #fff7e0,
    200 : #fff2cc,
    300 : #ffedb8,
    400 : #ffe9a8,
    500 : #ffe599,
    600 : #ffe291,
    700 : #ffde86,
    800 : #ffda7c,
    900 : #ffd36b,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #fffbf3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


/* For use in src/lib/core/theming/_palette.scss */
$md-warn: (
    50 : #f9e0e0,
    100 : #f0b3b3,
    200 : #e68080,
    300 : #db4d4d,
    400 : #d42626,
    500 : #cc0000,
    600 : #c70000,
    700 : #c00000,
    800 : #b90000,
    900 : #ad0000,
    A100 : #ffd7d7,
    A200 : #ffa4a4,
    A400 : #ff7171,
    A700 : #ff5858,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-warn-dark: (
    50 : #ffeff0,
    100 : #fed6da,
    200 : #febbc1,
    300 : #fea0a8,
    400 : #fd8b96,
    500 : #fd7783,
    600 : #fd6f7b,
    700 : #fc6470,
    800 : #fc5a66,
    900 : #fc4753,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffebec,
    A700 : #ffd1d4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



// mandatory stuff for theming
$app-primary: mat-palette($md-primary);
$app-accent:  mat-palette($md-accent);
$app-warn:  mat-palette($md-warn);
$app-warn-dark:  mat-palette($md-warn-dark);

$app-theme: mat-light-theme(( 
    color: ( 
        primary: $app-primary, 
        accent: $app-accent, 
        warn: $app-warn, 
    )
));

@include angular-material-theme($app-theme);

$app-dark-theme: mat-dark-theme(( 
    color: ( 
        primary: $app-primary, 
        accent: $app-accent, 
        warn: $app-warn-dark, 
    )
));

.dark-mode{
    @include angular-material-theme($app-dark-theme);
}